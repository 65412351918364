import React       from "react";
import { Service } from "@uLib/application";



const flattenDic = (dic) => {
  return Object.keys(dic).reduce((newDic, key) => {
    let value = dic[key];
    if (typeof value === "object") {
      value = Object.keys(value).reduce((v, lang) => {
        v[lang] = value[lang].value + "" === value[lang].value ? value[lang].value : value[lang];
        return v;
      }, {});
    }    
    newDic[key] = value;
    return newDic;
  }, {});
}

export default class I18nService extends Service{
  constructor(dictionnaries, acceptedLanguages, current, component){
    super("i18n", ["simple-storage"]);
    this._dictionnary         = flattenDic((Array.isArray(dictionnaries) ? dictionnaries : [dictionnaries]).reduce((dic, d) => Object.assign(dic, d), {}));
    this._acceptedLanguages   = acceptedLanguages;
    this._current             = current;
    this._component           = component;
  }
  
  clone(){
    return new I18nService(this._dictionnary, this._acceptedLanguages, this._current, this._component);
  }

  start() {
    return this.waitReady(['simple-storage']).then(async ([simpleStorage]) => { 
      simpleStorage.has("currentLanguage").then(has => {
        if(has) {
          simpleStorage.get("currentLanguage", true).then(currentLanguage => this._current = currentLanguage); 
        }
      })
    })
  }
  
  get acceptedLanguages(){
    return this._acceptedLanguages.slice();
  }
  get currentLanguage(){
    return this._current;
  }
  set currentLanguage(language){
    if(!this._acceptedLanguages.filter(acceptedLanguage => acceptedLanguage.iso === language.iso).length){
      throw new Error("Not accepted language");
    }
    this._current = language;
    this.application.getService("simple-storage").set("currentLanguage", language, true);
    this.triggerUpdate(this._current);
  }
  translate(data, bind){
    if(data + "" === data){
      return this.translateString(data, bind);
    }else if(data instanceof Object){
      return this.translateObject(data);
    } else {
      return "";
    }
  }
  translateObject(object){
    return object[this._current.bs];
  }
  has(name){
    return !!this._dictionnary[name];
  }
  translateString(name, bind = {}, forceResultAsString = false){
    if(!this.has(name)) return null;
    let traduction = this._dictionnary[name];
    if(traduction[this._current.iso] + "" === traduction[this._current.iso]){
      traduction = traduction[this._current.iso];
    }
    
    if(!Object.keys(bind).length){
      return traduction;
    }
    traduction = Object.keys(bind).reduce((traductions, key) => {
      const regex = new RegExp(`\%${key}\%`, 'g');
      return traductions.reduce((traductions, traduction) => {
        if(traduction !== traduction + ""){
          return traductions.concat([traduction]);
        }else{
          const results = traduction.split(regex);
          if(results.length === 1){
            return traductions.concat(results);
          }else{
            for(let i = 0; i < results.length - 1; ++i){
              traductions.push(results[i]);
              traductions.push(bind[key]);
            }
            traductions.push(results[results.length - 1]);
            return traductions;
          }
        }
      }, []) 
    }, [traduction]);

    const traductions = traduction.filter(f => f !== undefined && f !== null).reduce((acc, traduction) => {
      if(!acc.length){
        return [traduction];
      }
      if(acc[acc.length - 1] instanceof Object || traduction instanceof Object){
        acc.push(traduction);
      }else{
        acc[acc.length - 1] = acc[acc.length - 1] + traduction;
      }
      return acc;
    }, []);

    if(forceResultAsString){
      return traductions.join("");
    }else{
      return traductions.map((traduction, idx) => {
        if(traduction instanceof Object){
          return React.cloneElement(traduction, { key: idx });
        }else{
          return React.createElement(this._component, { key: idx }, traduction);
        }
      });
    }
  }
  

  queryProperty(field){
    return `${field}.${this.currentLanguage.bs}`;
  }
}