// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-thirdpartyemailvalidation-container {
  padding: 50px 100px;
}

.bs-thirdpartyemailvalidation-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.bs-thirdpartyemailvalidation-header>img {
  width: 300px;
}


.bs-thirdpartyemailvalidation-content  {

}`, "",{"version":3,"sources":["webpack://./src/components/thirdPartyEmailValidation.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;;AAGA;;AAEA","sourcesContent":[".bs-thirdpartyemailvalidation-container {\n  padding: 50px 100px;\n}\n\n.bs-thirdpartyemailvalidation-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 50px;\n}\n\n.bs-thirdpartyemailvalidation-header>img {\n  width: 300px;\n}\n\n\n.bs-thirdpartyemailvalidation-content  {\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
