import React, { FunctionComponent } from "react";
import Text, { Style } from "@cComponents/text";
import T from "@uBehaviour/i18n";

import "./thirdPartyEmailValidation.css";
import Modal from "@common/components/modal";

type ThirdPartyEmailValidationProps = {
  close : () => void;
}

const ThirdPartyEmailValidation: FunctionComponent<ThirdPartyEmailValidationProps> = ({ close }) => (
  <Modal.Show close={ close } style={{ width: "800px"}}>
    <div className="bs-thirdpartyemailvalidation-container">
      <div className="bs-thirdpartyemailvalidation-header">
        <img src={`/images/logos/betterstreet_color_text_horizontal.svg`}className="bs-thirdpartyemailvalidation-bs-logo" alt="Logo BetterStreet" />
      </div>
      <div className="bs-thirdpartyemailvalidation-content">
        <Text.Paragraph style={ Style.bold.huge.black.center }>
          <T>thirdPartyEmailValidation_felicitation</T>   
        </Text.Paragraph>
        <Text.Paragraph style={ Style.large.black.center }>
          <T>thirdPartyEmailValidation_cityThanks</T>
        </Text.Paragraph>
        <Text.Paragraph style={ Style.large.black.center }>
          <T>thirdPartyEmailValidation_createYourAccount</T>
        </Text.Paragraph>
      </div>
    </div>
  </Modal.Show>
);

export default ThirdPartyEmailValidation;
